<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('paykrub_transfer_out.head_add') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-form ref="form" v-model="formValid">
        <v-card>
          <v-card-title> {{ $t('paykrub_transfer_out.bt_create_bank') }}<v-spacer />
          </v-card-title>
          <v-card-actions>
            <v-row no-gutters>
              <!-- <v-row class=""> -->
              <!-- <v-col cols="4" class=""> -->
              <v-select class="px-1" :label="$t('paykrub_transfer_out.bank_code')"
                :placeholder="$t('paykrub_transfer_out.bank_code')" v-model="bankFromValue" @change="fbankFrom"
                :items="bankFrom" item-text="text" :rules="[v => !!v || '']" return-object dense outlined />
              <v-text-field class="px-1" :label="$t('paykrub_transfer_out.acc_number')" v-model="amount" type="number"
                :placeholder="$t('paykrub_transfer_out.acc_number')" dense outlined />
              <v-text-field class="px-1" :label="$t('paykrub_transfer_out.acc_name')" v-model="bankName" type="text"
                :placeholder="$t('paykrub_transfer_out.acc_name')" dense outlined />
              <!-- </v-col>
                <v-col cols="4" class=""> -->
              <!-- </v-col>
              </v-row> -->
            </v-row>
          </v-card-actions>
          <v-divider class="px-3" />
          <v-card-actions>
            <v-spacer />
            <v-btn @click="onBack" :loading="loading" color="primary" class="white--text px-5">
              <v-icon small class="px-2">mdi-home</v-icon>
              {{ $t('back') }}
            </v-btn>
            <v-btn @click="validateTransferOut" :loading="loading" color="success" class="white--text px-5">
              <v-icon small class="px-2">mdi-cash</v-icon>
              {{ $t('paykrub_transfer_out.transfer_out') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>

  </v-row>
</template>

<script>
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import jwt_decode from "jwt-decode";


export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      formValid: true,
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      loading: false,
      loadingTable: false,
      headers: [
        {
          text: this.$t('paykrub_transfer_out.header1'),
          value: 'amount',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header2'),
          value: 'accountFrom',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header2_2'),
          value: 'accountFrom',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header3'),
          value: 'accountTo',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header3_2'),
          value: 'accountTo',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header4'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header5'),
          value: 'detail',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header6'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('paykrub_transfer_out.header7'),
          value: 'actionBy',
          width: '100px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [
        // {
        //   detail: 'ถอนเงิน | ทำรายการสำเร็จ Log ID #1',
        //   status: 'สำเร็จ',
        // },
      ],
      bankItems: [],
      bankFrom: [],
      bankFromValue: [],
      bankAccountFrom: '',
      bankAccountName: '',
      bankTo: '',
      bankToAccount: '',
      bankToAccountName: '',
      amount: '',
      bankName: '',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      bankKey: this.$store.getters.getKeyBank,
      adminData: null,
    }
  },
  async created() {
    // try {
    //   this.bankItems = await this.$store.dispatch('getTypeBankData')
    //   let rs = await this.$store.dispatch('getAccountBank', {
    //     search: 'SCB',
    //     page: 1,
    //     rows: 10,
    //   })
    //   rs.data.map(e => {
    //     e['text'] = `${e.bank_account} : ${e.bank_name}`
    //   })
    //   this.bankFrom = rs.data
    // } catch (e) { }

    // let check = await this.$store.dispatch('getTypeBankData')
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) await this.getTransferOut(newValue.page, newValue.itemsPerPage)
      },
      deep: true,
    },
  },
  methods: {
    fbankFrom() {
      this.bankAccountFrom = this.bankFromValue.bank_account
      this.bankAccountName = this.bankFromValue.bank_name
    },
    async addBank() { },
    onBack() {
      this.$router.push({ name: 'paykrubTransferOut' })
    },
    async validateTransferOut() {
      // let role = null;
      // const localAuth = JSON.parse(localStorage.getItem('vuex'))
      // role = localAuth?.auth.userinfo.role

      // if (role === 'SuperAdmin') {
      //   if (this.$refs.form.validate()) {
      //     let auth2fa = false
      //     let userData = null
      //     const localAuth = JSON.parse(localStorage.getItem('vuex'))
      //     auth2fa = localAuth?.auth.userinfo.auth2fa === 1 ? true : false
      //     userData = localAuth?.auth.token ? jwt_decode(localAuth.auth.token) : null;

      //     if (!auth2fa) {
      //       this.$swal.fire({ icon: 'error', title: this.$t('transfer_out.open_2fa') })
      //     } else {
      //       this.$swal({
      //         title: '2FA',
      //         html: `
      //         <div>
      //           <p>`+ this.$t('transfer_out.fill_6_digit') + `</p>
      //         </div>
      //       `,
      //         input: 'text',
      //         inputAttributes: {
      //           inputmode: 'numeric',
      //           pattern: '[0-9]*',
      //           maxlength: 6,
      //         },
      //         inputValidator: value => {
      //           if (!value || isNaN(value)) {
      //             return this.$t('transfer_out.fill_number')
      //           }
      //           if (value && value.length !== 6) {
      //             return this.$t('transfer_out.fill_6_digit_please')
      //           }
      //         },
      //         inputPlaceholder: this.$t('transfer_out.fill_here'),
      //         showCancelButton: true,
      //         confirmButtonText: this.$t('confirm'),
      //         cancelButtonText: this.$t('exit'),
      //       }).then(async result => {
      //         if (result.isConfirmed) {
      //           const sendData = {
      //             id: userData.id,
      //             pin: result.value,
      //           }
      //           const res = await this.$store.dispatch('verify2fa', sendData)
      //           if (res.status) {
      //             this.transferOut()
      //           } else {
      //             this.$swal.fire(this.$t('transfer_out.wrong_pin'), '', 'error')
      //           }
      //         }
      //       })
      //     }
      //   }
      // } else {
      //   this.$swal.fire(this.$t('transfer_out.wrong_role'), '', 'error')
      // }
    },
    async transferOut() {
      // this.loading = true
      // if (this.$refs.form.validate()) {
      //   const params = {
      //     from_account: this.bankFromValue.bank_account.toString(),
      //     to_account: this.bankToAccount.toString(),
      //     amount: parseFloat(this.amount),
      //   }

      //   try {
      //     const rs = await this.$store.dispatch('addTransferOut', params)
      //     await this.$store.dispatch('updateTransfer', { id: rs.id, status: 'success' })
      //     await this.getTransferOut(this.pagination.page, this.pagination.itemsPerPage)
      //     this.$swal.fire(this.$t('transfer_out.transfer_complete'), '', 'success')

      //     this.bankFromValue = []
      //     this.amount = ''
      //     this.bankTo = ''
      //     this.bankAccountFrom = ''
      //     this.bankAccountName = ''
      //     this.bankToAccount = ''
      //     this.bankToAccountName = ''
      //     this.$refs.form.reset()
      //   } catch (e) {
      //     if (
      //       !e.message.includes('Unexpected token') ||
      //       !e?.includes('Unexpected token') ||
      //       !e.message.includes('Unexpected identifier')
      //     ) {
      //       this.$swal.fire({
      //         icon: 'error',
      //         title: e.message,
      //       })
      //     }
      //   }
      // }
      // this.loading = false
    },
    async getTransferOut(page, row, search) {
      // this.loadingTable = true
      // try {
      //   this.itemsData = []
      //   let rs = await this.$store.dispatch('getTranferOut', {
      //     // time_from: this.$refs.startDate.value,
      //     // time_to: this.$refs.endDate.value,
      //     // page: page,
      //     // rows: row,
      //     ...(!search ? { page: page } : {}),
      //     ...(!search ? { rows: row } : {}),
      //     ...(search ? { search: search } : {}),
      //   })
      //   this.pagination.totalItems = rs.count
      //   rs.data.forEach(e => {
      //     this.itemsData.push({
      //       id: e.id,
      //       amount: e.amount,
      //       accountFrom: e.accountbank
      //         ? `${this.bankKey[e.accountbank.bank] ? this.bankKey[e.accountbank.bank] : e.accountbank.bank} | ${e.accountbank.account
      //         } | ${e.accountbank.name}`
      //         : '',
      //       accountTo: `${this.bankKey[e.to_bank] ? this.bankKey[e.to_bank] : e.to_bank} | ${e.to_account} | ${e.to_name
      //         }`,
      //       status: e.status,
      //       detail: `${this.$t("transfer_out.withdraw")} | ${e.status === 'wait' ? this.$t("transfer_out.withdraw_wait") : this.$t("transfer_out.withdraw_complete")} Log ID #1`,
      //       date: moment(e.created_at).format('YY-MM-DD HH:mm'),
      //       actionBy: e.admin ? e.admin.name : '',
      //     })
      //   })
      //   // this.$router.push({ name: 'bank' })
      // } catch (e) {
      //   if (
      //     !e.message.includes('Unexpected token') ||
      //     !e?.includes('Unexpected token') ||
      //     !e.message.includes('Unexpected identifier')
      //   ) {
      //     this.$swal.fire({
      //       icon: 'error',
      //       title: e.message,
      //     })
      //   }
      // }
      // this.loadingTable = false
    },
    async searchKeyword(value) {
      await this.getTransferOut(1, this.pagination.itemsPerPage, value)
    },
  },
}
</script>
